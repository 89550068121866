﻿/// <reference path="./@types/saop_core/saopCommonServices.d.ts" />
/// <reference path="./@types/saop_core/saopTranslationsServices.d.ts" />
//import * as icSaopCore from "./@types/saop_core/index"
//import * as icSaopCore from './@types/saop_core/index'
//import * as saopCommonServices from "./@types/saop_core/saopCommonServices"
import { SaopSettingsView } from "./Settings/saop-settings-view";
import { SaopChangePwdView } from './Account/saop-change-pwd-view';


export function echo(): void {
    icSaopCore.echoTest("test");
}

// export function checkCompatibility(): boolean {
//     return icSaopCore.checkCompatibility();
// }

// export function initClientLogModeValue():void {
//     icSaopCore.initClientLogModeValue();
// }

// export function initSiteBaseUrlValue():void {
//     icSaopCore.initSiteBaseUrlValue();
// }

// export function initializeSiteJS():void {
//     icSaopCore.initializeSiteJS();
// }

// export function setFirstControlToFocus(formId: string):void {
//     icSaopCore.setFirstControlToFocus(formId);
// }

export class SaopSettingsViewEx extends SaopSettingsView { };

export class SaopChangePwdViewEx extends SaopChangePwdView { };



